<template>
	<v-container fluid>
		<v-card>
			<v-card-title>
				{{ $t('registeredSuccess') }}
			</v-card-title>
			<v-card-text>
				<p>
					{{ $t('registeredMessage') }}
				</p>
				<p>
					{{ $t('registeredRequestNew') }}
				</p>
			</v-card-text>
			<v-card-actions>
				<v-btn color="primary" @click="requestNewVerificationEmail">
					{{ $t('requestNewValidationEmail') }}
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-container>
</template>

<script>
export default {
	data() {
		return {
			url: process.env.VUE_APP_BASE_URL || 'http://localhost:5000/api/v1',
			isLoading: false,
		};
	},
	methods: {
		async requestNewVerificationEmail() {
			try {
				this.isLoading = true;

				await this.$http.post(
					`${this.url}/auth/requestverify/${this.$route.params.id}`,
				);

				this.isLoading = false;
			} catch (error) {
				this.$toasted.error(this.$t('generalError'), {
					duration: 3000,
					icon: 'check-circle',
				});

				this.isLoading = false;
			}
		},
	},
};
</script>

<style lang="scss" scoped></style>
